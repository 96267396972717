/***** Fonts *****/
/***** Fonts - DINEngschriftStd *****/
@font-face {
  font-family: "DINEngschriftStd";
  src: url("../fonts/DINEngschriftStd/DINEngschriftStd.eot");
  src: url("../fonts/DINEngschriftStd/DINEngschriftStd.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/DINEngschriftStd/DINEngschriftStd.woff2") format("woff2"),
    url("../fonts/DINEngschriftStd/DINEngschriftStd.woff") format("woff"),
    url("../fonts/DINEngschriftStd/DINEngschriftStd.ttf") format("truetype"),
    url("../fonts/DINEngschriftStd/DINEngschriftStd.svg#MarsBook") format("svg");
}

body {
  font-family: "DINEngschriftStd" !important;
  background-color: #ffd204 !important;
}
.d-contents {
  display: contents;
}

/* bit.dev */
.bit-heading,
.bit-header,
.bit-feild,
.bit-footer,
.bit-button {
  font-family: "DINEngschriftStd" !important;
}
.bit-dev {
  position: relative;
}
.bit-dev-button {
  width: fit-content;
  margin: auto;
}
.bit-button#btn-country {
  width: 200px;
  font-size: 1.25rem;
}
.bit-hover-card {
  pointer-events: none;
}
.bit-dev-header .bit-hover-card {
  opacity: 0;
  position: absolute;
  right: 3rem;
  top: 4.2rem;
}

.bit-dev-heading .bit-hover-card,
.bit-dev-image-with-content .bit-hover-card {
  opacity: 0;
  position: absolute;
  right: 3rem;
  bottom: 0;
  margin-bottom: 0;
}

.bit-dev-input-element .bit-hover-card {
  opacity: 0;
  position: absolute;
  right: 3rem;
  bottom: -7px;
  margin-bottom: 0;
  width: 17rem;
}

.country-selection .bit-dev-button .bit-hover-card,
.bit-dev-button .bit-hover-card {
  opacity: 0;
  position: absolute;
  right: 0rem;
  left: 0rem;
  margin: auto;
  bottom: -1px;
  width: 17rem;
}
.rio-question .bit-dev-button .bit-hover-card,
.country-selection .bit-dev-button .bit-hover-card {
  bottom: -24px;
}
.bit-dev-image .bit-hover-card,
.bit-dev-footer .bit-hover-card {
  opacity: 0;
  position: absolute;
  right: 3rem;
  bottom: 0px;
  margin-bottom: 0;
}
.bit-dev-image:hover .bit-hover-card,
.bit-dev-footer:hover .bit-hover-card,
.bit-dev-button:hover .bit-hover-card,
.bit-dev-input-element:hover .bit-hover-card,
.bit-dev-image-with-content:hover .bit-hover-card,
.bit-dev-heading:hover .bit-hover-card,
.bit-dev-header:hover .bit-hover-card {
  display: block;
  opacity: 1;
  z-index: 999;
}
.bit-hover-card .content {
  background-color: #edebfc;
  color: #55317f;
}
.form-check,
.bit-image,
.bit-footer,
.bit-button,
.bit-feild,
.bit-heading,
.bit-content-image,
.bit-header {
  outline: 2px solid transparent;
  border-radius: 10px;
}
.bit-feild {
  padding: 3px;
}
.form-check:hover,
.bit-image:hover,
.bit-footer:hover,
.bit-button:hover,
.bit-feild:hover,
.bit-heading:hover,
.bit-content-image:hover,
.bit-header:hover {
  outline-color: #edebfc;
}

/***** Main Navigation *****/
header.bit-header,
.main-nav {
  position: absolute;
  background-color: transparent !important;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  top: 10px;
  right: 15px;
  font-size: 24px;
  z-index: 999;
}
.bit-header,
.main-nav li a {
  color: #531e0d;
}
form .bit-button,
form .bit-feild {
  font-family: "DINEngschriftStd" !important;
}
.navbar-nav .nav-link {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.winner-form {
  background-color: #ffd204;
  position: relative;
  z-index: 99;
}

.main-content {
  background-image: linear-gradient(#1e7c8f, #4397b4);
}
.main-content .heading {
  font-size: 40px;
  text-shadow: -2px 2px 0 black;
  line-height: 1.1;
}
.main-content .heading-lg {
  font-size: 45px;
}
.main-content h2 {
  font-size: 40px;
  text-shadow: -2px 2px 0 black;
}
.main-content h3 {
  font-size: 30px;
  text-shadow: -2px 2px 0 black;
}
.main-content h4 {
  font-size: 25px;
  text-shadow: -2px 2px 0 black;
}
.main-content h5 {
  font-size: 23px;
  text-shadow: -2px 2px 0 black;
}
.main-content h6 {
  font-size: 1rem;
  margin: 0;
}

.stars-left,
.stars-right {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 150px;
}
.stars-left {
  top: 60%;
  left: 0;
}
.stars-right {
  right: 0;
}

.packs {
  z-index: 99;
}
.packs-stars-left,
.packs-stars-right {
  width: 100px;
}
.packs-stars-left {
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.packs-stars-right {
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.form-stars-left-top {
  top: -60px;
  width: 35px;
}
.form-stars-left-center {
  top: 35%;
  width: 90px;
}
.form-stars-left-bottom {
  bottom: -65px;
  width: 90px;
}
.form-stars-right-top {
  top: -68px;
  right: 0;
  width: 45px;
}
.form-stars-right-center {
  top: 35%;
  right: 0;
  width: 100px;
}
.form-stars-right-bottom {
  bottom: -15px;
  right: 0;
  width: 90px;
}

.page-stars-left-top {
  top: 15px;
  width: 25px;
}
.page-stars-left-bottom {
  bottom: -15px;
  width: 25px;
}
.page-stars-right-top {
  top: -10px;
  right: 0;
  width: 32px;
}
.page-stars-right-bottom {
  right: 0;
  bottom: -20px;
  width: 40px;
}

form .btn-dark {
  background-color: black;
  font-size: 24px;
  padding: 0.5rem 4rem;
}
form .form-select,
form .form-control {
  color: #531d0d;
  font-size: 18px;
}
form label {
  font-size: 18px;
}
form .input-error {
  color: #ffd204;
  /* color: red; */
}
form.form-winner .input-error {
  color: red;
}
form.home .form-control {
  font-size: 18px;
  color: #531d0d;
  padding: 0 0.75rem;
  text-transform: uppercase;
  height: 50px;
}
form.home .form-control[type="checkbox"] {
  background: none;
}
.roi-form .custom-control {
  padding-left: 0;
}
.roi-form .custom-control h3 {
  font-size: 26px;
  text-shadow: none;
}
.roi-form .error {
  font-size: 18px;
  text-shadow: none;
}
.roi-form .custom-control-label::before,
.roi-form .custom-control-label::after {
  display: none;
}
.roi-form .custom-radio .custom-control-input {
  position: relative;
  z-index: -9;
}
.roi-form .custom-radio .custom-control-input ~ .custom-control-label > div {
  transition: all 0.5s;
}
.roi-form
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label
  > div {
  /* opacity: 0.5; */
  transform: translate(0, -5px);
  transition: all 0.5s;
}
.roi-form
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label
  > div
  img {
  box-shadow: 0 0 10px #ffd204;
  border-radius: 50%;
}
.roi-form
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label
  > div
  h3 {
  color: #ffd204;
}

/***** Footer *****/
#mars-footer-panel.mars-footer-icons
  .mars-footer-list-right
  .mars-footer-icon
  strong {
  color: #e3000b !important;
}
#mars-footer-panel.mars-footer-icons .mars-footer-list-right .mars-footer-icon {
  color: #fff !important;
  position: relative !important;
  left: 0 !important;
}
#mars-footer-panel.mars-footer-icons
  .mars-footer-list-right
  a
  .mars-footer-icon {
  left: 0 !important;
}
#mars-footer-panel.mars-footer-icons
  .mars-footer-list-right
  a
  .mars-footer-label {
  color: #531e0d;
}
.mars-footer-legal p {
  color: #531e0d !important;
  padding-top: 20px;
}

/***** Terms and Conditions *****/

.faq .barcode {
  width: 50%;
}

.prizes h5 {
  text-shadow: none;
  line-height: 30px;
}
.prizes h6 {
  line-height: 20px;
}
/***** Contact Us *****/
.ff-form-main {
  max-width: 600px !important;
  margin: 0 auto;
}
.ff-type-text,
.ff-textarea,
.ff-select-type,
.ff-select-type option {
  width: 100% !important;
  height: 44px !important;
  color: #000 !important;
  padding: 10px;
  margin-bottom: 20px;
}
#btnsubmit {
  margin: 0 auto;
  float: left;
  border-style: none;
}
#dvFastForms .btnDiv input {
  padding: 0.5rem 4rem;
  border-radius: 50rem !important;
  text-transform: uppercase !important;
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  line-height: 1.5;
  font-size: 30px;
}
.ff-section-header {
  font-size: 30px;
  margin-top: 30px;
}
.ff-col-2.ff-field-col:not(.docsignWrapper) {
  width: 100%;
}
.ff-fileupload-select {
  color: #000;
}
.ff-col-2.ff-field-col label {
  color: #000;
}

/***** Google Captcha *****/
.grecaptcha-badge {
  z-index: 99999;
}

/***** Modals *****/
.modal-content {
  background: rgba(34, 130, 162, 0.9);
  border-radius: 0;
  border: 0;
}
.modal-header {
  border-bottom: 0;
}
.modal-header .close {
  display: none;
}
.modal-title {
  width: 100%;
}
.modal-title h2 {
  font-size: 30px;
  text-shadow: -2px 2px 0 black;
  line-height: 1.1;
}
.alert-modals .modal-content {
  background: #fff;
}
/*Loading Indicator */
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5) url(../images/loading-indicator.svg) no-repeat
    center;
  background-size: 80px 80px;
}
/***** Cookie Button *****/
#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
