/***** Mobile First *****/

/*Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap*/

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .bg-hero {
    background-image: url(../images/tv-show.png);
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: 0 60px;
  }
  .main-content .heading {
    font-size: 52px;
    text-shadow: -3px 3px 0 black;
  }
  .main-content h2 {
    font-size: 50px;
  }
  .main-content h3 {
    font-size: 40px;
  }
  .main-content h4 {
    font-size: 35px;
  }
  .main-content h5 {
    font-size: 35px;
  }
  .main-content h6 {
    font-size: 20px;
  }
  .main-content .heading-lg {
    font-size: 90px;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .faq p {
    font-size: 20px;
  }

  form.home .form-control {
    font-size: 22px;
    padding: 0 1rem;
    height: 66px;
  }

  .stars-left,
  .stars-right {
    width: 50px;
  }

  .packs-stars-left,
  .packs-stars-right {
    width: 130px;
  }

  .roi-stars-left-top {
    top: -60px;
    width: 120px;
  }
  .roi-stars-left-center {
    top: 32%;
    width: 50px;
  }
  .roi-stars-left-bottom {
    bottom: -40px;
    width: 120px;
  }
  .roi-stars-right-top {
    top: -175px;
    right: 0;
    width: 100px;
  }
  .roi-stars-right-bottom {
    bottom: 20px;
    right: 0;
    width: 90px;
  }
  .roi-form .custom-control h3 {
    font-size: 36px;
  }
  .roi-form .error {
    font-size: 28px;
  }

  .home-stars-left-top {
    top: -20px;
    width: 90px;
  }
  .home-stars-left-center {
    top: 35%;
    width: 40px;
  }
  .home-stars-left-bottom {
    bottom: -70px;
    width: 90px;
  }
  .home-stars-right-top {
    top: -30px;
    right: 0;
    width: 80px;
  }
  .home-stars-right-bottom {
    bottom: 30px;
    right: 0;
    width: 90px;
  }

  .form-stars-left-top {
    top: 20px;
    width: 50px;
  }
  .form-stars-left-center {
    top: 35%;
    width: 90px;
  }
  .form-stars-left-bottom {
    bottom: -65px;
    width: 90px;
  }
  .form-stars-right-top {
    top: -10px;
    right: 0;
    width: 60px;
  }
  .form-stars-right-center {
    top: 35%;
    right: 0;
    width: 100px;
  }
  .form-stars-right-bottom {
    bottom: -15px;
    right: 0;
    width: 90px;
  }

  .page-stars-left-top {
    width: 40px;
  }
  .page-stars-left-bottom {
    width: 45px;
  }
  .page-stars-right-top {
    width: 55px;
  }
  .page-stars-right-bottom {
    width: 70px;
  }
  /***** Modals *****/
  .modal-title h2 {
    font-size: 44px;
  }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .main-content .heading {
    font-size: 70px;
  }
  .main-content h2 {
    font-size: 60px;
  }
  .main-content h3 {
    font-size: 50px;
  }
  .main-content h4 {
    font-size: 38px;
  }
  .main-content h5 {
    font-size: 32px;
  }
  .main-content h6 {
    font-size: 20px;
  }
  .main-content .heading-lg {
    font-size: 90px;
  }

  .faq p {
    font-size: 20px;
  }
  form label {
    font-size: 20px;
  }
  form .form-select,
  form .form-control {
    font-size: 20px;
  }
  form .btn-dark {
    font-size: 30px;
  }

  .stars-left,
  .stars-right {
    width: 80px;
  }

  .packs-stars-left,
  .packs-stars-right {
    width: 150px;
  }

  .roi-stars-left-top {
    top: -80px;
    width: 140px;
  }
  .roi-stars-left-center {
    top: 40%;
    width: 60px;
  }
  .roi-stars-left-bottom {
    bottom: -50px;
    width: 150px;
  }
  .roi-stars-right-top {
    top: -215px;
    right: 0px;
    width: 120px;
  }
  .roi-stars-right-bottom {
    bottom: 20px;
    right: 0;
    width: 110px;
  }
  .roi-form .custom-control h3 {
    font-size: 40px;
  }
  .roi-form .error {
    font-size: 30px;
  }

  .home-stars-left-top {
    top: -30px;
    width: 140px;
  }
  .home-stars-left-center {
    top: 40%;
    width: 60px;
  }
  .home-stars-left-bottom {
    bottom: -85px;
    width: 150px;
  }
  .home-stars-right-top {
    top: -45px;
    right: 0px;
    width: 120px;
  }
  .home-stars-right-bottom {
    bottom: 50px;
    right: 0;
    width: 120px;
  }

  .page-stars-left-top {
    width: 60px;
  }
  .page-stars-left-bottom {
    width: 60px;
  }
  .page-stars-right-top {
    width: 70px;
  }
  .page-stars-right-bottom {
    width: 90px;
  }
  /***** Modals *****/
  .modal-title h2 {
    font-size: 60px;
  }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .main-content .heading {
    font-size: 80px;
  }
  .main-content h2 {
    font-size: 70px;
  }
  .main-content .heading-lg {
    font-size: 110px;
  }

  .stars-left,
  .stars-right {
    width: 110px;
  }
  .packs-stars-left,
  .packs-stars-right {
    width: 200px;
  }

  .form-stars-left-top {
    top: 20px;
    width: 100px;
  }
  .form-stars-left-center {
    top: 40%;
    width: 150px;
  }
  .form-stars-left-bottom {
    bottom: -65px;
    width: 150px;
  }
  .form-stars-right-top {
    top: -20px;
    right: 0;
    width: 100px;
  }
  .form-stars-right-center {
    top: 42%;
    right: 0;
    width: 150px;
  }
  .form-stars-right-bottom {
    bottom: -15px;
    right: 0;
    width: 150px;
  }
}

@media (min-width: 1400px) {
}

/***** Non-Mobile First *****/

/*Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
}

/*Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .bg-hero {
    background-image: url(../images/tv-show.png);
    background-repeat: no-repeat;
    background-size: 135%;
    background-position: 0 50px;
  }
  .bg-hero > div {
    /* margin-top: 21rem; */
    /* margin-top: 48vh; */
    margin-top: 22.5vh;
  }

  .stars-left-top-m {
    top: -60px;
    left: 0;
    width: 40px;
  }
  .stars-left-center-m {
    top: 40%;
    left: 0;
    width: 30px;
  }
  .stars-left-bottom-m {
    bottom: -35px;
    left: 0;
    width: 35px;
  }

  .stars-right-top-m {
    top: -60px;
    right: 0;
    width: 40px;
  }
  .stars-right-center-m {
    top: 60%;
    right: 0;
    width: 30px;
  }
  .stars-right-bottom-m {
    bottom: -18px;
    right: 10px;
    width: 35px;
  }
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
}

/*Extra large devices (large desktops)
No media query since the extra-large breakpoint has no upper bound on its width*/

@media (max-width: 736px) and (orientation: landscape) {
  .bg-hero {
    background-size: 80%;
  }
  .bg-hero > div {
    margin-top: 20vh;
    width: 50%;
    margin-left: 50%;
  }
}
